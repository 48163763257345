

const headerExpandEl = document.getElementById('headerExpand')
if (headerExpandEl) {
    headerExpandEl.addEventListener('click', () => {
        document.getElementById('headerExpandable').classList.toggle('hidden')
    })
}

const footerExpandEl = document.getElementById('footerExpand')
if (footerExpandEl) {
    footerExpandEl.addEventListener('click', () => {
        document.getElementById('footerExpandable').classList.toggle('hidden')
    })
}


let expandTriggers = document.getElementsByClassName('expandTrigger');
for (let index = 0; index < expandTriggers.length; index++) {
    const element = expandTriggers.item(index);
    const expandableId = element.getAttribute('data-expand')
    const expandableList = document.getElementsByClassName('expandableItem');
    element.addEventListener('click', () => {
        
        document.getElementById(expandableId).classList.toggle('hidden');
        for (let index = 0; index < expandableList.length; index++) {
            const item = expandableList.item(index);
            if(item.id !== expandableId) {
                item.classList.add('hidden');
            }
        }
    })
    console.log(element);    
}


const alertEl = document.querySelector('#alert > i')
if (alertEl) {
    alertEl.addEventListener('click', () => {
        document.getElementById('alert').remove();
    })
    setTimeout(() => {
        document.getElementById('alert').remove();
    }, 5000)
}